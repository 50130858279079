(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("mobxReact"), require("ReactDOM"), require("gsap"), require("sbBffIntegration"));
	else if(typeof define === 'function' && define.amd)
		define("aggregates", ["React", "mobx", "sbInternalMsgBus", "sbRespBlockLib", "mobxReact", "ReactDOM", "gsap", "sbBffIntegration"], factory);
	else if(typeof exports === 'object')
		exports["aggregates"] = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("mobxReact"), require("ReactDOM"), require("gsap"), require("sbBffIntegration"));
	else
		root["aggregates"] = factory(root["React"], root["mobx"], root["sbInternalMsgBus"], root["sbRespBlockLib"], root["mobxReact"], root["ReactDOM"], root["gsap"], root["sbBffIntegration"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__53__, __WEBPACK_EXTERNAL_MODULE__54__, __WEBPACK_EXTERNAL_MODULE__114__) {
return 